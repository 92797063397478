import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const { REACT_APP_SPEC_URL } = process.env;

function App() {
    return (
        <SwaggerUI url={REACT_APP_SPEC_URL} defaultModelsExpandDepth={-1} deepLinking={true}/>
    );
}

export default App;
